import { createSelector } from '@reduxjs/toolkit'
import {
  type SearchablePatientFrag,
  type AppointmentDisplayFrag,
  type DescribeUsersByCareManagerId,
  NoticeCode,
  NoticeLevel,
  type PatientNotice,
  patientSearchApi,
  userAdminApi,
  InsuranceOrdinality,
  CMChannelItemOverview,
} from '@valerahealth/rtk-query'
import {
  GridFilterItem,
  QueryDslQueryContainer,
  OperatorValueDate,
  handleDateTimeOsFilter,
  getOsGridDateOperators,
  OperatorValueSingleSelect,
  handleSelectOsFilter,
} from '@valerahealth/ui-components/grid'
import { Chip, theme } from '@valerahealth/ui-components'
import {
  formatInTimeZone,
  SYSTEM_TIMEZONE,
} from '@valerahealth/ui-components/utils/date'
import { PatientSearchColumnKey } from './columns'

const InsuranceSortOrder = {
  [InsuranceOrdinality.Primary]: 0,
  [InsuranceOrdinality.Secondary]: 1,
  [InsuranceOrdinality.Tertiary]: 2,
}

type AggResType =
  | {
      nestedAggregate?: {
        careTeamMemberIds?: {
          buckets?: { key: string; doc_count: number }[]
        }
      }
    }
  | undefined

const selectIdsSelector = createSelector(
  (res: AggResType) => res?.nestedAggregate?.careTeamMemberIds?.buckets,
  (buckets) => buckets?.map((v) => v.key),
)

const toOptionValuesSelector = createSelector(
  (res?: DescribeUsersByCareManagerId) => res?.describeUsersByCareManagerId,
  (usrs) =>
    usrs?.map((v) => ({
      value: v.careManagerId ?? '',
      label: v.display.expandedName ?? '',
    })),
)

// uses search patients aggreagates to get all care team member ids, then uses user summary to get display names
export const useCareTeamAggregates = () => {
  const aggregatesRes = patientSearchApi.useGetAggregatesQuery(
    {
      nestedAggregate: {
        nested: { path: 'careTeam' },
        aggs: {
          careTeamMemberIds: {
            terms: { field: 'careTeam.careManagerId.keyword', size: 10000 },
          },
        },
      },
    },
    {
      selectFromResult: ({ data, isLoading, isError, isFetching, error }) => {
        return {
          data: selectIdsSelector(data),
          isLoading,
          isError,
          isFetching,
          error,
        }
      },
    },
  )

  const { data, isLoading, isFetching, isError, error } =
    userAdminApi.useDescribeUsersByCareManagerIdQuery(
      {
        ids: aggregatesRes.data || [],
      },
      {
        skip: !aggregatesRes.data?.length,
        selectFromResult: ({
          data,
          isLoading,
          isFetching,
          isError,
          error,
        }) => ({
          data: toOptionValuesSelector(data),
          isLoading,
          isFetching,
          isError,
          error,
        }),
      },
    )

  return {
    data,
    isLoading: isLoading || aggregatesRes.isLoading,
    isFetching: isFetching || aggregatesRes.isFetching,
    isError: isError || aggregatesRes.isError,
    error: error || aggregatesRes.error,
  }
}

const mapNoticeLevelToColor = (noticeLevel: NoticeLevel) => {
  switch (noticeLevel) {
    case NoticeLevel.Error:
      return theme.palette.error.main
    case NoticeLevel.ErrorDark:
      return theme.palette.error.dark
    case NoticeLevel.ErrorLight:
      return theme.palette.error.light
    case NoticeLevel.Info:
      return theme.palette.info.main
    case NoticeLevel.InfoDark:
      return theme.palette.info.dark
    case NoticeLevel.InfoLight:
      return theme.palette.info.light
    case NoticeLevel.Primary:
      return theme.palette.primary.main
    case NoticeLevel.PrimaryDark:
      return theme.palette.primary.dark
    case NoticeLevel.PrimaryLight:
      return theme.palette.primary.light
    case NoticeLevel.Secondary:
      return theme.palette.secondary.main
    case NoticeLevel.SecondaryDark:
      return theme.palette.secondary.dark
    case NoticeLevel.SecondaryLight:
      return theme.palette.secondary.light
    case NoticeLevel.Success:
      return theme.palette.success.main
    case NoticeLevel.SuccessDark:
      return theme.palette.success.dark
    case NoticeLevel.SuccessLight:
      return theme.palette.success.light
    case NoticeLevel.Warning:
      return theme.palette.warning.main
    case NoticeLevel.WarningDark:
      return theme.palette.warning.dark
    case NoticeLevel.WarningLight:
      return theme.palette.warning.dark
    default:
      return theme.palette.info.main
  }
}

// More added in the future
export const WarningFilterOptions = [
  NoticeCode.EligibilityNotVerified,
  NoticeCode.EmrsyncImportantUnsynced,
  NoticeCode.MissingConsent,
  NoticeCode.MissingCreditCard,
  NoticeCode.PriorAuthRequired,
]

export const WarningChip = ({
  notice: { level, label },
}: {
  notice: PatientNotice
}): JSX.Element => {
  return (
    <Chip
      sx={{
        height: '20px',
        backgroundColor: mapNoticeLevelToColor(level),
        color: 'white',
        m: '2px 0px 2px 0px',
      }}
      label={label}
      title={label}
    />
  )
}

export const quickFilterFields = ['name.fullName', 'mrn', 'primaryEmail']

// func
export const formatAppt = (
  { startDateTime, type, careManagerDisplay }: AppointmentDisplayFrag,
  timezone?: string,
): string => {
  return [
    formatInTimeZone(
      new Date(startDateTime),
      timezone || SYSTEM_TIMEZONE,
      'M/d/yy, p zzz',
    ),
    type,
    careManagerDisplay,
  ]
    .filter((v) => v)
    .join(' - ')
}

export const patientToRow = (
  searchablePatient: SearchablePatientFrag,
  idsWithTreatmentNotificationMap: Map<string, CMChannelItemOverview[]>,
) => {
  const {
    mrn,
    name,
    status,
    dateOfBirth,
    primaryPhone,
    primaryEmail,
    timeZone,
    upcomingAppointments: _upcomingAppointments,
    genderIdentity,
    videoRoomId,
    careTeam,
    notices,
    dischargeReason,
    billingInsurances,
    tags,
    treatmentId,
  } = searchablePatient
  const upcomingAppointments = [...(_upcomingAppointments || [])].filter(
    (appt) => new Date(appt.endDateTime) > new Date(),
  )
  upcomingAppointments.sort((appt1, appt2) =>
    new Date(appt1.startDateTime) < new Date(appt2.startDateTime) ? -1 : 1,
  )
  const [nextAppointment] = upcomingAppointments || []
  return {
    _patient: searchablePatient,
    [PatientSearchColumnKey.id]: treatmentId,
    [PatientSearchColumnKey.mrn]: mrn || '',
    [PatientSearchColumnKey.name]: name,
    [PatientSearchColumnKey.status]: status,
    [PatientSearchColumnKey.dateOfBirth]: dateOfBirth || '',
    [PatientSearchColumnKey.primaryPhone]: primaryPhone || '',
    [PatientSearchColumnKey.primaryEmail]: primaryEmail || '',
    [PatientSearchColumnKey.timeZone]: timeZone || '',
    [PatientSearchColumnKey.insurance]:
      billingInsurances
        ?.filter(
          // ensure we have a value to display as these can be completely blank
          (value) => !!value.insurancePlanId || !!value.outOfNetworkPlan?.name,
        )
        .sort(
          (a, b) => InsuranceSortOrder[a.type] - InsuranceSortOrder[b.type],
        ) || [],
    [PatientSearchColumnKey.genderIdentity]: genderIdentity || '',
    [PatientSearchColumnKey.nextAppointment]: nextAppointment,
    [PatientSearchColumnKey.upcomingAppointments]: upcomingAppointments,
    videoRoomId: videoRoomId || '',
    [PatientSearchColumnKey.careTeam]:
      careTeam?.find((m) => m.isPrimary) || null,
    [PatientSearchColumnKey.npctm]: careTeam?.filter((m) => !m.isPrimary) || [],
    [PatientSearchColumnKey.warnings]: notices,
    [PatientSearchColumnKey.dischargeReason]: dischargeReason,
    [PatientSearchColumnKey.tags]: tags || [],
    [PatientSearchColumnKey.messages]: idsWithTreatmentNotificationMap.get(
      treatmentId,
    )?.length
      ? `${
          idsWithTreatmentNotificationMap.get(treatmentId)?.length
        } unread message${
          idsWithTreatmentNotificationMap.get(treatmentId)?.length !== 1
            ? 's'
            : ''
        }`
      : '',
  }
}

export type PatientSearchGridRowModel = ReturnType<typeof patientToRow>

export const filterForUpcomingAppointments: QueryDslQueryContainer = {
  nested: {
    path: 'upcomingAppointments',
    query: {
      bool: {
        filter: {
          exists: {
            field: 'upcomingAppointments',
          },
        },
        must: {
          range: {
            'upcomingAppointments.endDateTime': {
              gt: 'now',
            },
          },
        },
      },
    },
    inner_hits: {
      size: 20,
      sort: [{ 'upcomingAppointments.startDateTime': 'asc' }],
    },
  },
}

const nextApptQueryIsNotEmpty: QueryDslQueryContainer = {
  bool: {
    filter: filterForUpcomingAppointments,
  },
}
const nextApptQueryIsEmpty: QueryDslQueryContainer = {
  bool: {
    must_not: nextApptQueryIsNotEmpty,
  },
}

export const handleUpcomingAppointmentsFilter = (
  item: GridFilterItem,
  field: string,
) => {
  if (item.operator === OperatorValueDate.isNotEmpty)
    return nextApptQueryIsNotEmpty
  if (item.operator === OperatorValueDate.isEmpty) return nextApptQueryIsEmpty

  const filter = handleDateTimeOsFilter(item, field)
  if (!filter) return filter

  const enhanced: QueryDslQueryContainer = {
    bool: {
      filter: filterForUpcomingAppointments,
      must: {
        nested: {
          path: 'upcomingAppointments',
          query: {
            bool: {
              must: [
                {
                  range: {
                    'upcomingAppointments.endDateTime': {
                      gte: 'now',
                    },
                  },
                },
                filter,
              ],
            },
          },
        },
      },
    },
  }
  return enhanced
}

export const nextAppointmentFilterOperators = getOsGridDateOperators().filter(
  ({ value }) =>
    (
      [
        OperatorValueDate.isEmpty,
        OperatorValueDate.isNotEmpty,
        OperatorValueDate.before,
        OperatorValueDate.onOrBefore,
      ] as string[]
    ).includes(value),
)

export const handleCareTeamFilter =
  (primary: boolean) =>
  (item: GridFilterItem, field: string): QueryDslQueryContainer | undefined => {
    if (item.operator === OperatorValueSingleSelect.isEmpty) {
      return {
        bool: {
          must_not: {
            nested: {
              path: 'careTeam',
              query: {
                bool: {
                  must: {
                    term: {
                      'careTeam.isPrimary': primary,
                    },
                  },
                },
              },
            },
          },
        },
      }
    }

    if (item.operator === OperatorValueSingleSelect.isNotEmpty) {
      return {
        nested: {
          path: 'careTeam',
          query: {
            bool: {
              must: {
                term: {
                  'careTeam.isPrimary': primary,
                },
              },
            },
          },
        },
      }
    }
    const filter = handleSelectOsFilter(item, field)
    if (!filter) return filter
    return {
      nested: {
        path: 'careTeam',
        query: {
          bool: {
            must: [
              {
                term: {
                  'careTeam.isPrimary': primary,
                },
              },
              filter,
            ],
          },
        },
      },
    }
  }
