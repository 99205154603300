import {
  MakeMaybe,
  MakeNonNullable,
  ParsedTaskFragment,
  PatientPreferenceValue,
} from '@valerahealth/rtk-query'

export enum ColumnKey {
  type = 'type',
  status = 'status',
  patient = 'patient',
  serviceType = 'serviceType',
  referralSource = 'referralSource',
  description = 'description',
  assignee = 'assignee',
  dueDate = 'dueDate',
  comment = 'comment',
  createdBy = 'createdBy',
  createdDate = 'createdDate',
  lastComment = 'lastComment',
  lastCommentDate = 'lastCommentDate',
  actions = 'actions',
  patientPreferences = 'patientPreferences',
  selection = '__check__', //
}

type PartialTaskFields = Pick<
  ParsedTaskFragment,
  'type' | 'status' | 'referralSource' | 'serviceType' | 'assignee'
>

export type PatientTaskField = {
  patientId: string
  treatmentId: string
  display?: string | null
  mrn?: string | null
}

export type TaskFormType = MakeMaybe<
  PartialTaskFields,
  keyof PartialTaskFields
> & {
  dueDate: Date | null
  patient: PatientTaskField | null
  comment: string
  description: string
  patientPreferences: PatientPreferenceValue
}
// after validation
export type SubmittedTaskFormType = Pick<
  TaskFormType,
  | 'serviceType'
  | 'referralSource'
  | 'comment'
  | 'description'
  | 'patient'
  | 'patientPreferences'
> &
  // required fields
  MakeNonNullable<
    Pick<TaskFormType, 'assignee' | 'dueDate' | 'type' | 'status'>
  >
