import { useCallback, useMemo, useState } from 'react'
import {
  careManagerApi,
  PatientFragment,
  PatientGenderIdentity,
  PatientLegalSex,
  PatientPronounsEnum,
  patientRegistrationApi,
  QuestionnaireName,
  UpdatePatientInput,
} from '@valerahealth/rtk-query'
import { useSelector } from 'react-redux'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  Typography,
  Divider,
  Grid,
  Box,
  useNotify,
  Stack,
  setIntlPhoneNumber,
  parseIntlPhoneNumber,
} from '@valerahealth/ui-components'
import {
  FloatingSaveButton,
  FormProvider,
  onlyDirtyFields,
  useForm,
} from '@valerahealth/ui-components/form'
import { RootState } from 'redux/reducers'
import {
  dateToStr,
  getUSTimezone,
  strToDate,
  SYSTEM_TIMEZONE,
} from '@valerahealth/ui-components/utils/date'
import { useOutletContext } from 'react-router'
import { useReduxDispatch } from 'redux/store'
import { TreatmentRoomRouteContext } from '../utilities/treatments'
import PatientDetails from './PatientDetails'
import OnBoarding from './OnBoarding'
import AppUsage from './AppUsage'
import Contacts from './Contacts'
import PeripheralDevices from './PeripheralDevices'
import PhoneAlreadyExist from '../common/PhoneAlreadyExist'
import { SIDEBAR_WIDTH } from '../TreatmentRoom'

type DisplayAlreadyType = {
  sameClinic: boolean
  data: UpdatePatientInput
}

const patientToForm = (patient?: PatientFragment) => {
  const { care, demographics } = patient || {}

  const {
    scaleId,
    hospitalized,
    disableChat,
    diagnosis,
    device,
    medicalDiagnosis,
    questionnaires,
  } = care || {}

  const {
    patientId,
    firstName,
    middleName,
    lastName,
    username,
    preferredName,
    pronouns,
    mrn,
    gender,
    genderIdentity,
    genderDetails,
    phone,
    primaryPhone,
    email,
    primaryEmail,
    status,
    dateOfBirth,
    timezone,
    picture,
    languages,
    preferredLanguage,
    videoLink,
    addressComponents,
  } = demographics || {}

  return {
    picture: picture || '',
    profileId: patientId || '',
    firstName: firstName || '',
    lastName: lastName || '',
    addressComponents: addressComponents || {
      street: '',
      city: '',
      state: '',
      zipcode: '',
    },
    gender: gender || PatientLegalSex.O,
    genderIdentity: genderIdentity || PatientGenderIdentity.Other,
    genderDetails: genderDetails || '',
    phone: phone ? parseIntlPhoneNumber(phone.trim()) : '',
    primaryPhone: primaryPhone ? parseIntlPhoneNumber(primaryPhone.trim()) : '',
    email: email || '',
    primaryEmail: primaryEmail || '',
    dateOfBirth: dateOfBirth ? strToDate(dateOfBirth) : null,
    status: status!,
    middleName: middleName || '',
    preferredLanguage: preferredLanguage || '',
    languages: languages || [],
    username: username || '',
    mrn: mrn || '',
    diagnosis: diagnosis || [],
    medicalDiagnosis: medicalDiagnosis || [],
    scaleId: scaleId || '',
    phqScore:
      questionnaires?.find((x) => x && x.name === QuestionnaireName.Phq)
        ?.score || '',
    gad7Score:
      questionnaires?.find((x) => x && x.name === QuestionnaireName.Gad7)
        ?.score || '',
    videoLink: videoLink || '',
    useMobileApp: (device && device.useMobileApp) || false,
    patientMessaging: !disableChat,
    preferredName: preferredName || '',
    pronouns: pronouns || PatientPronounsEnum.Other,
    timezone: timezone || getUSTimezone(SYSTEM_TIMEZONE),
    hospitalized: hospitalized || false,
  }
}

type PatientBasicInfoForm = ReturnType<typeof patientToForm>

const formToPatient = (
  submittedData: Partial<PatientBasicInfoForm>,
): UpdatePatientInput => {
  const {
    picture,
    profileId,
    firstName,
    lastName,
    addressComponents,
    gender,
    genderIdentity,
    genderDetails,
    phone,
    email,
    primaryPhone,
    primaryEmail,
    dateOfBirth,
    status,
    middleName,
    preferredLanguage,
    languages,
    username,
    mrn,
    diagnosis,
    preferredName,
    pronouns,
    timezone,
    hospitalized,
    useMobileApp,
    patientMessaging,
    scaleId,
  } = submittedData || {}

  const response: UpdatePatientInput = {
    patientId: profileId!,
  }

  const demographics = {
    firstName,
    middleName,
    lastName,
    username,
    preferredName,
    pronouns,
    mrn,
    gender,
    genderIdentity,
    genderDetails,
    addressComponents,
    phone: phone ? setIntlPhoneNumber(phone.trim()) : undefined,
    primaryPhone: primaryPhone
      ? setIntlPhoneNumber(primaryPhone.trim())
      : undefined,
    email,
    primaryEmail,
    status,
    dateOfBirth: dateOfBirth ? dateToStr(dateOfBirth) : undefined,
    timezone,
    picture,
    languages,
    preferredLanguage,
    tags: diagnosis as string[],
  }

  const care = {
    hospitalized,
    scaleId: scaleId || undefined,
    ...(typeof patientMessaging === 'boolean'
      ? {
          disableChat: !patientMessaging,
        }
      : {}),
    ...(typeof useMobileApp === 'boolean'
      ? {
          device: {
            type: undefined,
            useMobileApp,
          },
        }
      : {}),
  }

  if (Object.values(demographics).some((x) => x !== undefined))
    response.demographics = demographics
  if (Object.values(care).some((x) => x !== undefined)) response.care = care
  return response
}

const TreatmentBasicInfo = () => {
  const notify = useNotify()
  const { t } = useTranslation()
  const [updatePatient, { isSuccess, isError }] =
    patientRegistrationApi.useUpdatePatientMutation()
  const dispatch = useReduxDispatch()
  const { openSidebar } = useSelector((state: RootState) => state.global)
  const { treatment, patient } = useOutletContext<TreatmentRoomRouteContext>()

  const [displayAlreadyExist, setDisplayAlreadyExist] =
    useState<DisplayAlreadyType | null>(null)

  const methods = useForm({
    defaultValues: patientToForm(patient),
  })

  const integrationLink = 'https://www.fitbit.com/global/us/connect123'
  const peripheralDevice = useCallback(
    () => <PeripheralDevices link={integrationLink} />,
    [integrationLink],
  )

  const sections = useMemo(
    () => [
      {
        title: t('patientDetails'),
        component: PatientDetails,
      },
      {
        title: t('onboarding'),
        component: OnBoarding,
      },
      {
        title: t('valeraAppUsage'),
        component: AppUsage,
      },
      {
        title: t('contacts'),
        component: Contacts,
      },
      {
        title: t('peripheralDevices'),
        component: peripheralDevice,
      },
    ],
    [peripheralDevice, t],
  )

  const updateData = async (
    input: UpdatePatientInput,
    forceMultiPhone?: boolean,
  ) => {
    const configuration =
      typeof forceMultiPhone === 'boolean'
        ? {
            ...input.configuration,
            forceMultiPhone: forceMultiPhone || false,
          }
        : undefined

    const res = await updatePatient({
      input: {
        ...input,
        configuration,
      },
    })

    if ('error' in res) {
      const { message } = res.error
      if (message === 'already_exists_in_same_clinic')
        setDisplayAlreadyExist({ sameClinic: true, data: input })
      else if (message === 'already_exists_in_different_clinic')
        setDisplayAlreadyExist({ sameClinic: true, data: input })
      else
        notify({
          message: message || t('serverError.500'),
          severity: 'error',
        })
      return
    }

    dispatch(
      careManagerApi.util.invalidateTags([
        {
          id: treatment.id,
          type: 'PatientProfile',
        },
      ]),
    )

    dispatch(
      patientRegistrationApi.util.invalidateTags([
        {
          id: treatment.id,
          type: 'patient',
        },
      ]),
    )

    notify({
      message: t('patientSuccessfullyUpdated'),
      severity: 'success',
    })
  }

  const onFormSubmit = async (patientForm: PatientBasicInfoForm) => {
    const { profileId, dateOfBirth, ...rest } = patientForm
    const { dateOfBirth: _dateOfBirth } = methods.formState.dirtyFields

    const _input = formToPatient({
      ...onlyDirtyFields(methods.formState.dirtyFields, {
        ...rest,
      }),
      dateOfBirth: _dateOfBirth ? dateOfBirth : undefined,
      profileId,
    })

    await updateData(_input)
  }

  const onFormValidationFail = () => {
    notify({
      message: t('form_invalid'),
      severity: 'warning',
    })
  }

  return (
    <>
      {displayAlreadyExist && (
        <PhoneAlreadyExist
          handleClose={() => {
            setDisplayAlreadyExist(null)
          }}
          sameClinic={displayAlreadyExist?.sameClinic}
          handleContinue={() => {
            updateData(displayAlreadyExist.data, true)
          }}
        />
      )}

      <Typography style={{ overflow: 'auto' }}>
        <Grid className="initial-css" sx={{ p: 3 }}>
          <FormProvider {...methods}>
            <Stack
              position="relative"
              spacing={3}
              component="form"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  return false
                }
              }}
              onSubmit={methods.handleSubmit(
                onFormSubmit,
                onFormValidationFail,
              )}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5" gutterBottom component="div">
                    {t('basicInfo')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{ padding: '20px 10px', m: 0 }}
                style={{ marginTop: 0 }}
              >
                {sections.map((section) => {
                  const Section = section.component

                  return (
                    <Box key={section.title}>
                      <Typography gutterBottom variant="h6">
                        {section.title}
                      </Typography>
                      <Grid sx={{ p: 2 }} style={{ position: 'relative' }}>
                        <Section />
                      </Grid>
                      <Divider sx={{ mb: 4, mt: 4 }} />
                    </Box>
                  )
                })}
              </Grid>
              <FloatingSaveButton
                boxSx={
                  openSidebar
                    ? {
                        right: `${SIDEBAR_WIDTH + 2}%`,
                      }
                    : undefined
                }
                sx={{
                  opacity: 1,
                }}
                label={t('save')}
                isError={isError}
                isSuccess={isSuccess}
              />
            </Stack>
          </FormProvider>
        </Grid>
      </Typography>
    </>
  )
}

export default TreatmentBasicInfo
