import { Link } from '@valerahealth/ui-components'
import DrCIconSVG from 'images/drChrono.svg'
import { PatientIntegrationFragment } from '@valerahealth/rtk-query'
import { CSSProperties } from 'react'

const DrChronoIcon = ({
  linkToTab,
  emrId,
  isActive,
  iconStyle,
  title,
}: Partial<PatientIntegrationFragment> & {
  linkToTab?: string
  iconStyle?: CSSProperties
  title?: string
}) => {
  if (!emrId) return null

  return (
    <Link
      to={`https://app.drchrono.com/patient_demographics/${emrId}/${
        linkToTab === 'authorizations' ? 'insurance_authorizations' : 'extra'
      }`}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation()
      }}
      underline="none"
      mx={1}
      sx={{
        filter: isActive ? undefined : 'grayscale(1)',
        m: 0,
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      title={
        title || (isActive ? undefined : 'Dr Chrono integration is disabled')
      }
    >
      <DrCIconSVG style={{ ...iconStyle }} />
    </Link>
  )
}
export default DrChronoIcon
