import { useMemo, useState } from 'react'
import { useReduxSelector } from 'redux/store'
import { ParsedTaskFragment } from '@valerahealth/rtk-query'
import { patientDisplayName, Mode, ReadWriteTaskForm } from '@valerahealth/ui-components'

export default function Task({
  task,
  handleClose,
  refetch = () => {},
}: {
  task: ParsedTaskFragment | null
  handleClose?: () => void
  refetch?: Function
}) {
  const [mode, setMode] = useState<Mode>(task ? Mode.READ : Mode.WRITE)

  const treatment = useReduxSelector(
    (state) => state.treatmentRoom.byId[state.treatmentRoom.selectedId || '']!,
  )

  const patient = useMemo(
    () => ({
      patientId: treatment.profile.profileId,
      treatmentId: treatment.id,
      display: patientDisplayName(treatment.profile),
      mrn: treatment.mrn || '',
    }),
    [treatment],
  )

  return (
    <ReadWriteTaskForm
      patient={patient}
      mode={mode}
      task={task}
      onEdit={() => setMode(Mode.WRITE)}
      onSaveSuccess={() => {
        if (handleClose) {
          handleClose()
        } else {
          setMode(Mode.READ)
        }
        refetch()
      }}
      onCancel={() => (handleClose ? handleClose() : setMode(Mode.READ))}
      onDeleteSuccess={() => refetch()}
    />
  )
}
