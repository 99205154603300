import { type ReactNode } from 'react'
import {
  Dialog,
  DialogContent,
  type DialogProps,
  DialogTitle,
  type DialogTitleProps,
  type DialogContentProps,
  DialogActions,
  type DialogActionsProps,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import IconButton from './IconButton'

export type ModalProps = {
  open: boolean
  onClose: () => void
  title: ReactNode
  children: ReactNode | ReactNode[]
  actionContent?: ReactNode | ReactNode[]
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>
  dialogTitleProps?: DialogTitleProps
  dialogContentProps?: DialogContentProps
  dialogActionsProps?: DialogActionsProps
}

export function Modal({
  open,
  onClose,
  title,
  children,
  actionContent,
  dialogProps,
  dialogTitleProps,
  dialogContentProps,
  dialogActionsProps,
}: ModalProps) {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...dialogTitleProps?.sx,
        }}
        {...dialogTitleProps}
      >
        {title}
        <IconButton title="Close" onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent {...dialogContentProps}>{children}</DialogContent>
      {actionContent && (
        <DialogActions {...dialogActionsProps}>{actionContent}</DialogActions>
      )}
    </Dialog>
  )
}
