import { careManagerApi, patientRegistrationApi } from '@valerahealth/rtk-query'
import {
  useNotify,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@valerahealth/ui-components'
import { AppRegistration } from '@valerahealth/ui-components/icons'
import { useTranslation } from '@valerahealth/ui-translation'
import { useReduxDispatch } from 'redux/store'

interface RegisterToEmrProps {
  treatmentId: string
}
const { useRegisterToEMRMutation } = careManagerApi

export function RegisterToEmr({ treatmentId }: RegisterToEmrProps) {
  const [t] = useTranslation()
  const notify = useNotify()
  const dispatch = useReduxDispatch()
  const [registerToEMR, registerToEMRStatus] = useRegisterToEMRMutation()
  return (
    <MenuItem
      disabled={registerToEMRStatus.isLoading}
      onClick={() => {
        registerToEMR(treatmentId).then((value) => {
          if ('data' in value) {
            notify({
              severity: 'success',
              message: t('PatientSearch.registerToEMRSuccess'),
            })
            dispatch(
              patientRegistrationApi.util.invalidateTags([
                { type: 'patient', id: treatmentId },
              ]),
            )
          } else {
            if ('data' in value.error) {
              notify({
                severity: 'error',
                message:
                  value.error.data.message === 'dr_chrono_doctor_id_not_found'
                    ? "The patient's care team member could not be found in the EMR"
                    : value.error.data.message ||
                      t('PatientSearch.registerToEMRError'),
              })
              return
            }
            notify({
              severity: 'error',
              message: t('PatientSearch.registerToEMRError'),
            })
          }
        })
      }}
    >
      <ListItemIcon>
        <AppRegistration />
      </ListItemIcon>
      <ListItemText>Register To EMR</ListItemText>
    </MenuItem>
  )
}
