import { useEffect } from 'react'
import {
  type CMDischargePatientReq,
  careManagerApi,
  configApi,
  dischargeReasonOptions,
  StandardListItemFrag,
} from '@valerahealth/rtk-query'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import {
  FormProvider,
  SaveButton,
  Select,
  TextField,
  useForm,
} from '@valerahealth/ui-components/form'
import { CloseOutlined } from '@valerahealth/ui-components/icons'
import LABELS from 'locales/en'
import { useReduxSelector } from 'redux/store'
import { isWellnessSelector } from 'redux/selectors'

type Form = CMDischargePatientReq['body']

const dischargeFormDefaultValue: Form = {
  reason: '',
  description: '',
}

const defaultDischargeReasons: StandardListItemFrag[] = []

export type DischargeFormProps = {
  treatmentId: string
  onSuccess: () => void
  onCancel: () => void
}

export type DischargePopupProps<Open extends true | false> = {
  open: Open
  treatmentId?: string
} & Omit<DischargeFormProps, 'treatmentId'>

export const DischargeForm = ({
  treatmentId,
  onSuccess,
  onCancel,
}: DischargeFormProps) => {
  const isWellness = useReduxSelector(isWellnessSelector)

  const notify = useNotify()
  const [dischargePatient, dischargePatientStatus] =
    careManagerApi.useDischargePatientMutation()

  const methods = useForm<Form>({
    defaultValues: dischargeFormDefaultValue,
  })

  const { dischargeReasons, isLoading, error } = configApi.useGetListMapQuery(
    ['dischargeReasons'],
    {
      skip: !isWellness,
      selectFromResult: ({ data, isLoading, error }) => ({
        dischargeReasons:
          data?.dischargeReasons?.list || defaultDischargeReasons,
        isLoading,
        error,
      }),
    },
  )

  useEffect(() => {
    if (error) {
      notify({
        severity: 'warning',
        message: error.message || 'Failed to load discharge reasons list.',
      })
    }
  }, [error, notify])

  const onFormSubmit = async ({ reason, description }: Form) => {
    const value = await dischargePatient({
      treatmentId,
      body: { reason, description: description || 'N/A' },
    })
    if ('error' in value) {
      notify({
        severity: 'error',
        message:
          'data' in value.error &&
          value.error.data.message === 'cm_not_authorized'
            ? LABELS.cm_not_authorized_discharge
            : LABELS.DischargePopup.dischargePatientError,
      })
      console.error(value.error)
    } else {
      notify({
        severity: 'success',
        message: LABELS.DischargePopup.dischargePatientSuccess,
      })
      onSuccess()
    }
  }

  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={methods.handleSubmit(onFormSubmit)}
      sx={{ pt: 1 }}
    >
      <FormProvider {...methods}>
        <Grid item xs={12}>
          <Typography sx={{ mt: '-12px' }}>
            {LABELS.DischargePopup.dischargePatientWarning}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            isLoading={isLoading}
            required
            fullWidth
            name="reason"
            label="Reason"
          >
            {isWellness
              ? dischargeReasons.map(({ label, value, id }) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))
              : dischargeReasonOptions.map((o) => (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Additional Details"
            name="description"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              color="error"
              sx={{ ml: 'auto' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <SaveButton
              label="DISCHARGE"
              isError={dischargePatientStatus.isError}
              isSuccess={dischargePatientStatus.isSuccess}
            />
          </Stack>
        </Grid>
      </FormProvider>
    </Grid>
  )
}

export function DischargePopup<Open extends true | false>({
  open,
  treatmentId,
  ...props
}: DischargePopupProps<Open>) {
  return (
    <Dialog open={!!open} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {LABELS.dischargePatient}
        <IconButton title="Close" onClick={props.onCancel}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open && treatmentId && (
          <DischargeForm {...props} treatmentId={treatmentId} />
        )}
      </DialogContent>
    </Dialog>
  )
}
