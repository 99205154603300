import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { isWellnessSelector } from 'redux/selectors'
import {
  type ParsedTaskFragment,
  TaskStatus,
  taskApi,
} from '@valerahealth/rtk-query'
import { TFunction, useTranslation } from '@valerahealth/ui-translation'
import {
  Box,
  Button,
  LinearProgress,
  TextField,
} from '@valerahealth/ui-components'
import { isValid } from '@valerahealth/ui-components/utils/date'
import Task from './Task'

const { useGetTasksByTreatmentIdQuery } = taskApi

const compareTimeFnAsc = (a: ParsedTaskFragment, b: ParsedTaskFragment) => {
  return a.dueDate < b.dueDate ? -1 : a.dueDate === b.dueDate ? 0 : 1
}

function sortTasks(tasks: ParsedTaskFragment[]) {
  const todoTasks = tasks
    .filter((t) => t.status === TaskStatus.ToDo && isValid(new Date(t.dueDate)))
    .sort(compareTimeFnAsc)
  const completedTasks = tasks
    .filter(
      (t) => t.status === TaskStatus.Completed && isValid(new Date(t.dueDate)),
    )
    .sort(compareTimeFnAsc)
  const voidTasks = tasks
    .filter((t) => t.status === TaskStatus.Void && isValid(new Date(t.dueDate)))
    .sort(compareTimeFnAsc)
  return [...todoTasks, ...completedTasks, ...voidTasks]
}

function filterTasks(
  tasks: ParsedTaskFragment[],
  words: string,
  t: TFunction,
  isWellness: boolean,
) {
  if (!words || words === '' || !tasks || tasks.length < 1) return tasks
  const wordsLowercase = words.toLowerCase()
  return tasks.filter(
    (v) =>
      (v.description && v.description.toLowerCase().includes(wordsLowercase)) ||
      (isWellness &&
        v.type &&
        t(`task.taskType.${v.type}`).toLowerCase().includes(wordsLowercase)) ||
      (v.assignee.display &&
        v.assignee.display.toLowerCase().includes(wordsLowercase)) ||
      v.comments?.some((c) => c.text.toLowerCase().includes(wordsLowercase)) ||
      (v.createdBy.display &&
        v.createdBy.display.toLowerCase().includes(wordsLowercase)) ||
      (v.referralSource &&
        v.referralSource.toLowerCase().includes(wordsLowercase)) ||
      (v.serviceType &&
        t(`task.taskServiceType.${v.serviceType}`)
          .toLowerCase()
          .includes(wordsLowercase)) ||
      (v.status &&
        t(`task.taskStatus.${v.status}`)
          .toLowerCase()
          .includes(wordsLowercase)),
  )
}

export default function TaskBar() {
  const treatmentId = useParams<{ treatmentId: string }>().treatmentId!
  const [t] = useTranslation()
  const [isCreate, setCreate] = useState<boolean>(false)
  const [words, setWords] = useState<string>('')

  const handleCreate = () => setCreate(true)
  const handleCancel = () => setCreate(false)
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWords(event.target.value)
  }

  const { data, isLoading, refetch } = useGetTasksByTreatmentIdQuery({
    treatmentId,
  })
  const sortedData = useMemo(() => sortTasks(data || []), [data])
  const isWellness = useSelector(isWellnessSelector)
  const tasks = useMemo(
    () => filterTasks(sortedData, words, t, isWellness),
    [sortedData, words, t, isWellness],
  )
  return (
    <Box display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          px: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label={t('Search Task')}
          margin="dense"
          id="searchTask"
          value={words}
          onChange={handleSearch}
        />
        <Button onClick={handleCreate}>{t('add')}</Button>
      </Box>
      <Box
        sx={{
          flex: '1 1 0px',
          overflowY: 'auto',
        }}
      >
        {isCreate && (
          <Box
            sx={{
              p: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Task
              task={null}
              handleClose={handleCancel}
              refetch={() => setTimeout(refetch, 1500)}
            />
          </Box>
        )}
        {isLoading && <LinearProgress />}
        {tasks.map((task) => (
          <Box
            key={task._id}
            sx={{
              p: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Task task={task} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
