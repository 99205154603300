import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import './style.less'
import {
  PatientGenderIdentity,
  PatientPronounsEnum,
} from '@valerahealth/rtk-query'
import { Popup, Form as FormComponent, IconButton } from '@valerahealth/ui-core'
import { useNavigate } from 'react-router-dom'
import dStrings from '../../../strings.json'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  loadPlans,
  loadPatientCareTeam,
  cancelEditPatient,
  updatePatient,
} from '../../../redux/actions/treatmentRoom'

import BdayIcon from '../../../images/icn_bday.svg'
import GenderIcon from '../../../images/icn_gender.svg'
import PlaceIcon from '../../../images/icn_place.svg'
import PhoneIcon from '../../../images/icn_phone.svg'
import MailIcon from '../../../images/icn_email.svg'
import MRNIcon from '../../../images/mrn.svg'
import AppIcon from '../../../images/phone_app.svg'
import RegisterQuesIcon from '../../../images/icn_register_questionnaire.svg'
import {
  loadProgramProviders,
  loadProgramTags,
  createProgramTag,
} from '../../../redux/actions/programActions'
import { genderEnum2, planType } from '../../utilities/enums'
import { getTimezoneWithGMT } from '../../utilities/treatments'
import PlanSelect from '../PlanSelect'
import PCTMSelect from '../PCTMSelect'
import TagsSelect from '../TagsSelect'
import { getCustomStyles } from '../../utilities'
import AdditionalContactFields from '../../TreatmentRoom/PatientDemographics/AdditionalContactFields'
import { loadCaseloadFilterNumbers } from '../../../redux/actions/caseloadActions'

import PhoneAlreadyExist from '../PhoneAlreadyExist'
import { setIntlPhoneNumber } from '@valerahealth/ui-components'
import { getTreatmentServerObject } from '../../TreatmentBasicInfo/utilities'

function EditPatientPopup({
  editedPatient,
  disableChatOptions,
  cancelEditPatient,
  loadPatientCareTeam,
  loadPlans,
  updatePatient,
  loadCaseloadFilterNumbers,
  programTags,
  loadProgramTags,
  createProgramTag,
}) {
  const { t } = useTranslation()
  const [patient, setPatient] = useState({})
  const [supportiveContact, setSupportiveContact] = useState({})
  const [displayError, setDisplayError] = useState(false)
  const [pctmMissing, setPctmMissing] = useState(false)
  const [invalidScale, setInvalidScale] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidMail, setInvalidMail] = useState(false)
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false)
  const [addAdditionalContact, setAddSupportiveContact] = useState(false)
  const [displayInvalidSupportiveContact, setDisplayInvalidSupportiveContact] =
    useState(false)
  const [displayAlreadyExist, setDisplayAlreadyExist] = useState()

  const _height = '36px'
  const customStyles = getCustomStyles(_height)

  const navigate = useNavigate()

  useEffect(() => {
    const p = { ...editedPatient }

    if (editedPatient.scaleId) {
      p.confirmScaleId = editedPatient.scaleId
      p.peripheralDevice = 'wirelessScale'
    }
    if (editedPatient.device && !editedPatient.device.useMobileApp) {
      p.patientWithoutApp = true
    }

    setPatient(p)
  }, [])

  useEffect(() => {
    if (!programTags) loadProgramTags()
    if (!patient.pending) {
      if (patient.id && !patient.careTeam) {
        loadPatientCareTeam()
      }
      if (patient.id && !patient.plans) {
        loadPlans()
      }
    }
  }, [])

  const checkPatientData = () => {
    let valid = true
    // required fields
    if (
      !patient.firstName.length ||
      !patient.lastName.length ||
      !patient.email.length ||
      !patient.gender.length ||
      !patient.phone.length ||
      !patient.dateOfBirth ||
      !patient.timezone
    ) {
      setDisplayError(true)
      valid = false
    }

    if ((!patient.id || patient.pending) && !patient.pctm) {
      setPctmMissing(true)
      valid = false
    }

    // check scale id
    if (
      patient.peripheralDevice === 'wirelessScale' &&
      (!patient.scaleId ||
        !patient.scaleId.length ||
        patient.scaleId !== patient.confirmScaleId ||
        /^[0-9a-fA-F]{24}$/.test(patient.scaleId))
    ) {
      setInvalidScale(true)
      setDisplayError(true)
      valid = false
    }

    if (!setIntlPhoneNumber(patient.phone)) {
      valid = false
      setInvalidPhone(true)
      setDisplayError(true)
    }

    if (supportiveContact.firstName) {
      if (!supportiveContact.phone || !supportiveContact.phone.length) {
        valid = false
        setDisplayInvalidSupportiveContact(true)
        setDisplayError(true)
      }
    }

    if (!valid) return false

    if (displayInvalidSupportiveContact) {
      setDisplayInvalidSupportiveContact(false)
    }

    if (invalidPhone) {
      setInvalidPhone(false)
    }

    if (invalidFirstName) {
      setInvalidFirstName(false)
    }
    if (invalidLastName) {
      setInvalidLastName(false)
    }
    if (invalidMail) {
      setInvalidMail(false)
    }

    if (displayError) {
      setDisplayError(false)
    }
    if (pctmMissing) {
      setPctmMissing(false)
    }

    return true
  }

  const getFields = (t) => {
    const fields = [
      {
        text: (
          <span className="edit-patient-label">
            {dStrings.firstName}
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'firstName',
        placeholder: 'John',
        type: 'text',
        invalid: invalidFirstName,
        invalidMessage: invalidFirstName && dStrings.invalidFirstName,
        required: true,
      },
      {
        text: (
          <span className="edit-patient-label">{dStrings.preferredName}</span>
        ),
        key: 'preferredName',
        placeholder: '',
        type: 'text',
      },
      {
        text: (
          <span className="edit-patient-label">
            {dStrings.lastName}
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'lastName',
        placeholder: 'Smith',
        type: 'text',
        invalid: invalidLastName,
        invalidMessage: invalidLastName && dStrings.invalidLastName,
        required: true,
      },
      {
        text: (
          <>
            {/* {showMore && <BdayIcon />} */}
            <BdayIcon />
            <span className="edit-patient-label">
              {' '}
              {dStrings.birthday}
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'dateOfBirth',
        type: 'date',
        min: new Date(1900, 1, 1),
        max: new Date(),
        required: true,
      },
      {
        text: (
          <>
            {/* {showMore && <GenderIcon />} */}
            <GenderIcon />
            <span className="edit-patient-label">
              {' '}
              {dStrings.gender}
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'gender',
        type: 'select',
        emptyOption: `<${dStrings.chooseGender}>`,
        select: genderEnum2,
        required: true,
      },
      {
        text: (
          <span className="edit-patient-label">{dStrings.genderIdentity}</span>
        ),
        key: 'genderIdentity',
        type: 'select',
        emptyOption: `<${dStrings.chooseGender1}>`,
        select: Object.fromEntries(
          Object.values(PatientGenderIdentity).map((k) => [
            k,
            t(`PatientGenderIdentity.${k}`),
          ]),
        ),
      },
      {
        text: <span className="edit-patient-label"> {dStrings.pronouns}</span>,
        key: 'pronouns',
        type: 'select',
        emptyOption: `<${dStrings.choosePronouns}>`,
        select: PatientPronounsEnum,
        border: true,
      },
      {
        text: (
          <>
            <MailIcon />
            <span className="edit-patient-label">
              {' '}
              {dStrings.emailPrimary}
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        required: true,
        key: 'email',
        placeholder: 'mail@mail.com',
        type: 'text',
        invalid: invalidMail,
        invalidMessage: invalidMail && dStrings.invalidMail,
      },
      {
        text: (
          <>
            {/* {showMore && <PhoneIcon />} */}
            <PhoneIcon />
            <span className="edit-patient-label">
              {' '}
              {dStrings.phone}
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'phone',
        placeholder: '+123456789',
        disabled: patient.pending,
        type: 'text',
        required: true,
        invalid: invalidPhone,
        invalidMessage: invalidPhone && dStrings.invalidPhone,
      },
      {
        text: (
          <>
            <PlaceIcon />
            <span className="edit-patient-label">{dStrings.address}</span>
          </>
        ),
        key: 'address',
        placeholder: '12 Sesame st., New York, NY',
        type: 'special',
        component: (
          <div className="address">
            <GooglePlacesAutocomplete
              selectProps={{
                isClearable: true,
                components: { DropdownIndicator: null },
                value: {
                  label: patient.address || '',
                  value: patient.address || '',
                },
                inputId: 'address-places',
                styles: customStyles,
                onChange: (adrs) => {
                  setPatient({
                    ...patient,
                    address: adrs ? adrs.value.description : null,
                  })
                },
              }}
            />
          </div>
        ),
      },
      {
        text: (
          <span className="edit-patient-label">
            {' '}
            {dStrings.timezone}
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'timezone',
        type: 'select',
        required: true,
        emptyOption: `<${dStrings.chooseTimezone}>`,
        select: getTimezoneWithGMT(),
      },
      {
        text: (
          <>
            <MRNIcon />
            <span className="edit-patient-label">{dStrings.MRN}</span>
          </>
        ),
        key: 'mrn',
        placeholder: '1234',
        type: 'text',
        border: true,
      },
      {
        text: (
          <>
            <AppIcon />
            <span className="edit-patient-label">
              {dStrings.patientWithoutApp}
            </span>
          </>
        ),
        lineClass: 'mobile-app',
        key: 'patientWithoutApp',
        type: 'switch',
      },
      {
        text: (
          <span className="edit-patient-label">
            {dStrings.patientMessaging}
          </span>
        ),
        lineClass: 'mobile-app',
        key: 'patientMessaging',
        type: 'switch',
        getVisible: () => {
          return disableChatOptions && disableChatOptions.active
        },
      },
      {
        text: dStrings.choosePeripheralDevice,
        key: 'peripheralDevice',
        type: 'select',
        emptyOption: `<${dStrings.choosePeripheralDevice}>`,
        select: {
          wirelessScale: dStrings.wirelessScale,
          null: dStrings.none,
        },
        border: patient.peripheralDevice !== 'wirelessScale',
      },
      {
        text: '',
        type: 'text',
        key: 'scaleId',
        placeholder: dStrings.deviceID,
        getVisible: (d) => d.peripheralDevice === 'wirelessScale',
      },
      {
        text: '',
        type: 'text',
        key: 'confirmScaleId',
        placeholder: dStrings.confirmDeviceID,
        getVisible: (d) => d.peripheralDevice === 'wirelessScale',
        border: true,
        invalidMessage: invalidScale && dStrings.invalidScale,
        invalid: invalidScale,
      },
      {
        text: (
          <>
            {/* {showMore && <RegisterQuesIcon />} */}
            <RegisterQuesIcon />
            <span className="edit-patient-label"> {dStrings.phqScore}</span>
          </>
        ),
        key: 'phqScore',
        placeholder: '1234',
        type: 'number',
      },
      {
        text: (
          <>
            <RegisterQuesIcon />
            <span className="edit-patient-label"> {dStrings.gad7Score}</span>
          </>
        ),
        key: 'gad7Score',
        placeholder: '1234',
        type: 'number',
      },
      {
        text: dStrings.tags,
        type: 'special',
        key: 'tags',
        component: (
          <TagsSelect
            styles={customStyles}
            patientTags={patient.diagnosis}
            programTags={programTags}
            createTag={createProgramTag}
            handleChange={(diagnosis) => {
              setPatient({ ...patient, diagnosis })
            }}
          />
        ),
      },
    ]

    if (!patient.id || patient.pending) {
      fields.push({
        text: dStrings.assignPlan,
        key: 'plans',
        type: 'special',
        component: (
          <PlanSelect
            styles={customStyles}
            patientPlans={patient && patient.plans}
            handleChange={(plans) => {
              setPatient({ ...patient, plans })
            }}
          />
        ),
      })

      fields.push({
        text: dStrings.PCTM,
        required: true,
        key: 'pctm',
        type: 'special',
        component: (
          <PCTMSelect
            pctmId={patient.pctm}
            pctmMissing={pctmMissing}
            styles={customStyles}
            handleChange={(pctm) => {
              setPatient({ ...patient, pctm })
            }}
          />
        ),
      })
    }

    return fields
  }

  const saveData = ({ forceMultiPhone }) => {
    if (!checkPatientData()) {
      return
    }

    setDisabledSaveBtn(true)

    const data = getTreatmentServerObject(
      patient,
      editedPatient.type,
      supportiveContact,
    )

    const cb =
      patient.id && !patient.pending
        ? undefined
        : (_treatment) => {
            if (!patient.id) loadCaseloadFilterNumbers()

            navigate(`/caseload/treatment/${_treatment.id}/timeline`)
          }

    updatePatient({
      patientData: { ...data, forceMultiPhone },
      treatmentId: patient.id,
      cb,
      pendingPatientId: patient.pending && patient.patientId,
      errorCb: (errorKey) => {
        setDisabledSaveBtn(false)
        switch (errorKey) {
          case 'email':
            setInvalidMail(true)
            setDisplayError(true)
            break
          case 'firstName':
            setInvalidFirstName(true)
            setDisplayError(true)
            break
          case 'lastName':
            setInvalidLastName(true)
            setDisplayError(true)
            break
          case 'already_exists_in_same_clinic':
            setDisplayAlreadyExist({ sameClinic: true })
            break
          case 'already_exists_in_different_clinic':
            setDisplayAlreadyExist({ sameClinic: false })
            break
          default:
            break
        }
      },
    })
  }

  return (
    <>
      {displayAlreadyExist && (
        <PhoneAlreadyExist
          handleClose={() => {
            setDisplayAlreadyExist()
          }}
          sameClinic={displayAlreadyExist.sameClinic}
          handleContinue={() => {
            saveData({ forceMultiPhone: true })
          }}
        />
      )}
      <Popup
        title={
          patient.id && !patient.pending
            ? dStrings.editPatientDetails
            : dStrings.addNewPatient
        }
        className="edit-patient-popup "
        buttons={[
          {
            text: dStrings.cancel,
            type: 'link',
            onClick: cancelEditPatient,
          },
          {
            text: dStrings.save,
            type: 'primary',
            disable: disabledSaveBtn,
            onClick: saveData,
          },
        ]}
        onClosed={cancelEditPatient}
      >
        <div>
          <FormComponent
            data={patient}
            handleDataChange={(_patient) => {
              if (invalidMail && _patient.email !== patient.email) {
                setInvalidMail(false)
              }
              setPatient(_patient)
            }}
            fields={getFields(t)}
            displayInvalid={displayError}
          />
          {!patient.id && (
            <div className="add-supportive-contact-container">
              {addAdditionalContact ? (
                <div className="add-supportive-contact-area">
                  <b>{dStrings.addAdditionalContact}</b>
                  <AdditionalContactFields
                    editedContact={supportiveContact}
                    handleChangeEditedContact={setSupportiveContact}
                    displayInvalid={displayInvalidSupportiveContact}
                  />
                </div>
              ) : (
                <IconButton
                  labelText={dStrings.addNewAdditionalContact}
                  type="plus"
                  onClick={() => {
                    setAddSupportiveContact(true)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Popup>
    </>
  )
}

function mapStateToProps(state) {
  const editedPatient = { ...state.treatmentRoom.editedPatient }
  if (editedPatient.id && !editedPatient.pending) {
    // if patient has pctm, data comes from shelve, so keep it as is. If not, need to be filled
    if (!editedPatient.pctm) {
      const profile = state.treatmentRoom.byId[state.treatmentRoom.selectedId]

      if (profile) {
        const allPlans = profile.plansDetails && profile.plansDetails.byId
        const plans = []

        for (const key in allPlans) {
          if (allPlans[key].type !== planType.INDIVIDUAL_PRIVATE) {
            plans.push(allPlans[key].assignment.templateId)
          }
        }

        editedPatient.careTeam = profile.careTeam
        editedPatient.pctm = profile.pctm
        editedPatient.plans = plans.length && plans
      }
    }
  } else {
    editedPatient.careTeam = []
  }

  let disableChatOptions
  try {
    disableChatOptions = state.program.configurations.disableChat
  } catch (error) {
    disableChatOptions = {}
  }

  return {
    editedPatient,
    programTags: state.program.tags,
    planTemplates: state.planTemplates.byId,
    disableChatOptions,
  }
}

const mapDispatchToProps = {
  cancelEditPatient,
  loadPatientCareTeam,
  loadPlans,
  loadProgramProviders,
  updatePatient,
  loadCaseloadFilterNumbers,
  loadProgramTags,
  createProgramTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientPopup)
