import { CSSProperties } from 'react'
import LumaIconSVG from '../images/luma.svg'
import { Link } from '../base'

export const LumaIcon = ({
  lumaId,
  iconStyle,
  title,
}: {
  lumaId: string
  iconStyle?: CSSProperties
  title?: string
}) => {
  return (
    <Link
      to={`https://next.lumahealth.io/patients/${lumaId}/`}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation()
      }}
      underline="none"
      mx={1}
      sx={{
        m: 0,
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      title={title}
    >
      <LumaIconSVG style={{ ...iconStyle }} />
    </Link>
  )
}

export default LumaIcon
