import { useMemo } from 'react'
import { careManagerApi, type CareManagerBasic } from '@valerahealth/rtk-query'
import { MenuItem, ListItemText, Checkbox } from '@valerahealth/ui-components'
import {
  Combobox,
  ValidationRule,
  type ComboboxProps,
} from '@valerahealth/ui-components/form'
import { useReduxSelector } from 'redux/store'
import { programIdSelector } from '@valerahealth/redux-auth'

export type ProviderSelectProps<
  Multiple extends boolean | undefined = undefined,
> = {
  name: string
  label: string
  multiple?: Multiple
  filterOptions?: (data: CareManagerBasic[]) => CareManagerBasic[]
  required?: string | ValidationRule<boolean>
} & Pick<
  // used to convert the value if its a different datatype than options
  ComboboxProps<CareManagerBasic, Multiple>,
  | 'setValueAs'
  | 'parseValue'
  | 'fullWidth'
  | 'disabled'
  | 'textFieldProps'
  | 'helperText'
>

export default function CMProviderSelect<
  Multiple extends boolean | undefined = undefined,
>({
  name,
  label,
  multiple,
  filterOptions = (data) => data?.filter((v) => v.isActive) || [],
  required,
  parseValue,
  fullWidth = true,
  ...rest
}: ProviderSelectProps<Multiple>) {
  const programId = useReduxSelector(programIdSelector)

  const careManagers = careManagerApi.useGetAllCareManagersQuery({
    programId,
  })
  const [allOptions, filteredOptions]: [
    CareManagerBasic[],
    CareManagerBasic[],
  ] = useMemo(() => {
    const data: CareManagerBasic[] = careManagers?.data || []
    const prefilteredOpttions = filterOptions(data)
    return [data, prefilteredOpttions]
  }, [filterOptions, careManagers?.data])

  const isLoading = careManagers?.isLoading || false
  const isError = careManagers?.isError || false

  return (
    <Combobox
      fullWidth={fullWidth}
      label={label}
      name={name}
      options={filteredOptions}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      parseValue={
        // ensures that if a value gets filtered out as a selectable option, they still displayed.
        parseValue ? (value) => parseValue(value, allOptions) : undefined
      }
      required={required}
      loading={isLoading}
      renderOption={(spread, { id, firstName, lastName }, { selected }) => (
        <MenuItem {...spread} key={id} selected={selected}>
          {multiple && <Checkbox key={id} readOnly checked={selected} />}
          <ListItemText key={id} primary={`${firstName} ${lastName}`} />
        </MenuItem>
      )}
      disableClearable={false}
      {...rest}
      helperText={isError ? 'Failed to load options' : rest.helperText}
    />
  )
}
