import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { SerializedError } from '@reduxjs/toolkit'
import {
  SupportiveContact,
  SupportiveContactRelationEnum,
  careManagerApi,
} from '@valerahealth/rtk-query'
import { Chip, Grid, Typography, useNotify } from '@valerahealth/ui-components'
import {
  EditableDataGrid,
  GridRenderCellParams,
} from '@valerahealth/ui-components/grid'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from '@valerahealth/ui-components/icons'
import { contactUseList } from '../utilities/enums'

const {
  useCreateAdditionalContactMutation,
  useGetSupportiveContactsQuery,
  useEditAdditionalContactMutation,
  useDeleteAdditionalContactMutation,
} = careManagerApi

const relationValueOptions = Object.values(SupportiveContactRelationEnum).sort()

const checkPrimary = (
  relation: SupportiveContact['relation'],
  rows: SupportiveContact[],
) => {
  return !rows.find((row) => row.relation === relation)
}

const regExpCheckName = /^[\p{L}()][\p{L}\d()' -]{0,70}$/u

const Contacts = () => {
  const { t } = useTranslation()
  const notify = useNotify()
  const treatmentId = useParams<{ treatmentId: string }>().treatmentId!

  const {
    data,
    isError,
    error,
    isLoading: isLoadingSupportiveContacts,
  } = useGetSupportiveContactsQuery({
    treatmentId,
  })
  const [createContact, createContactStatus] =
    useCreateAdditionalContactMutation()
  const [updateContact, updateContactStatus] =
    useEditAdditionalContactMutation()
  const [deleteContact, deleteContactStatus] =
    useDeleteAdditionalContactMutation()

  const rows = useMemo(() => data || [], [data])

  const handleError = useCallback(
    (
      error:
        | {
            data: {
              message?: string | undefined
              meta?: any
            }
          }
        | SerializedError,
    ) => {
      const msg = 'data' in error ? error.data.message : error.message
      /*
      notify({
        severity: 'error',
        message: msg || t('serverError.500'),
      })
      */
      return (msg || t('serverError.500')) as string
    },
    [t],
  )

  if (isError && error) {
    notify({
      severity: 'error',
      message: handleError(error),
    })
  }

  const handleSaveChanges = useCallback(
    async (val: SupportiveContact, _rows: SupportiveContact[]) => {
      const updateState = val.id !== 'new'

      if (!val.firstName) {
        return {
          error: t('isRequired').replace('{field}', t('firstName')),
        }
      }
      if (!regExpCheckName.test(val.firstName)) {
        return {
          error: 'Invalid first name',
        }
      }
      if (!regExpCheckName.test(val.lastName)) {
        return {
          error: 'Invalid last name',
        }
      }
      if (!val.phone) {
        return {
          error: t('isRequired').replace('{field}', t('phone')),
        }
      }

      let res
      const { id: _, ...contact } = val
      if (updateState) {
        res = await updateContact({
          treatmentId,
          contactId: val.id as string,
          contact: { ...contact },
        })
        if ('error' in res) {
          const msg = handleError(res.error)
          return {
            error: msg,
          }
        }
        notify({
          severity: 'success',
          message: 'Contact was successfully updated',
        })
        return { data: res.data }
      }
      const isPrimary = checkPrimary(val.relation, _rows)
      res = await createContact({
        treatmentId,
        contact: { ...contact, primary: isPrimary },
      })
      if ('error' in res) {
        const msg = handleError(res.error)
        return {
          error: msg,
        }
      }
      notify({
        severity: 'success',
        message: 'Contact was successfully created',
      })
      return { data: res.data }
    },
    [createContact, handleError, notify, t, treatmentId, updateContact],
  )
  const columns = useMemo(
    () => [
      {
        field: 'relation',
        headerName: t('relation'),
        type: 'singleSelect',
        valueOptions: relationValueOptions,
        width: 150,
        editable: true,
        renderCell: (
          params: GridRenderCellParams<
            SupportiveContact,
            SupportiveContact['relation']
          >,
        ) => {
          const _contact = params.row
          return <Chip label={_contact.relation} />
        },
      },
      {
        field: 'firstName',
        headerName: t('firstName'),
        width: 150,
        editable: true,
        required: true,
      },
      {
        field: 'lastName',
        headerName: t('lastName'),
        width: 150,
        editable: true,
      },
      {
        field: 'use',
        headerName: t('use'),
        type: 'singleSelect',
        valueOptions: contactUseList.map((u) => {
          return {
            label: t(u),
            value: u,
          }
        }),
        width: 110,
        editable: true,
        renderCell: (
          params: GridRenderCellParams<
            SupportiveContact,
            SupportiveContact['use']
          >,
        ) => {
          const _contact = params.row
          return (
            <Grid>
              <Typography
                onClick={
                  !_contact.primary
                    ? () => {
                        const newRows = rows.map((r) => {
                          if (
                            r.relation === _contact.relation &&
                            r.id !== _contact.id
                          ) {
                            const p = {
                              ...r,
                              primary: false,
                            }
                            return p
                          }
                          return r
                        })

                        const d = { ..._contact, primary: true }

                        handleSaveChanges(d, newRows)
                      }
                    : undefined
                }
              >
                {_contact.primary ? (
                  <StarIcon />
                ) : (
                  <StarBorderIcon titleAccess="Click for set as primary" />
                )}{' '}
              </Typography>
              {_contact.use ? t(_contact.use) : ''}
            </Grid>
          )
        },
      },
      {
        field: 'phone',
        headerName: t('phone'),
        editable: true,
        width: 150,
        required: true,
      },
      {
        field: 'email',
        headerName: t('email'),
        editable: true,
        width: 200,
      },
    ],
    [handleSaveChanges, rows, t],
  )

  const handleDelete = async (val: SupportiveContact) => {
    const res = await deleteContact({
      treatmentId,
      contactId: val.id as string,
    })
    if ('error' in res) {
      const msg = handleError(res.error)
      return {
        error: msg,
      }
    }
    notify({
      severity: 'success',
      message: 'Contact was successfully deleted',
    })
  }

  return (
    <EditableDataGrid
      loading={
        isLoadingSupportiveContacts ||
        createContactStatus.isLoading ||
        updateContactStatus.isLoading ||
        deleteContactStatus.isLoading
      }
      columns={columns}
      rows={rows}
      getRowHeight={() => 'auto'}
      onSave={handleSaveChanges}
      onDelete={handleDelete}
      onDeleteConfirm={() => {
        return {
          header: t('Delete Contact'),
          body: t('Are you sure you want to delete this contact?'),
          confirmLabel: t('yes'),
          cancelLabel: t('no'),
        }
      }}
      onAddRow={() => ({
        id: 'new',
        firstName: '',
        lastName: '',
        phone: '',
        use: null,
        relation: t('supportiveContact'),
      })}
      autoHeight
      addButtonOptions={{
        text: `+ ${t('addContact')}`,
        variant: 'text',
        className: 'add_contact_button',
        style: {
          position: 'absolute',
          top: '-40px',
          right: '20px',
          zIndex: '1',
        },
      }}
    />
  )
}

export default Contacts
