import { type ComponentPropsWithoutRef, useState } from 'react'
import { Box, Paper, Tab, Tabs } from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import TreatmentChannel from './Channel/TreatmentChannel'
import TaskBar from './TaskBar'

function TabPanel(
  props: ComponentPropsWithoutRef<'div'> & { value: number; index: number },
) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      id={`chat-sidebar-tabpanel-${index}`}
      aria-labelledby={`chat-sidebar-tab-${index}`}
      {...other}
      sx={{
        display: value === index ? 'flex' : 'none',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      {value === index && children}
    </Box>
  )
}
function a11yProps(index: number) {
  return {
    id: `chat-sidebar-${index}`,
    'aria-controls': `chat-sidebar-tabpanel-${index}`,
  }
}

const TreatmentSidebar = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <Tabs
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
        variant="fullWidth"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        <Tab
          sx={{ textTransform: 'uppercase' }}
          label={t('chat')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: 'uppercase' }}
          label={t('tasks')}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TreatmentChannel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TaskBar />
      </TabPanel>
    </Paper>
  )
}

export default TreatmentSidebar
