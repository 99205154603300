import { Box } from '../../../base'
import { TaskSearchGrid as SearchGrid } from './TaskSearchGrid'
import { TaskFormDialog } from './TaskFormDialog'

export function TaskSearchGrid() {
  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <SearchGrid />
      <TaskFormDialog />
    </Box>
  )
}
