import { lazy, useEffect, useMemo } from 'react'
import { ConnectedProps, connect, shallowEqual } from 'react-redux'
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom'
//@ts-ignore
import { Confirmation, CallLoader } from '@valerahealth/ui-core'
import { datadogRum } from '@datadog/browser-rum'
import {
  Notifications as AlertNotifications,
  CenteredSpinner,
  LazyComponent,
  TaskSearchGrid,
} from '@valerahealth/ui-components'
import { Permission, checkPermissions } from '@valerahealth/redux-auth'
import { careManagerApi } from '@valerahealth/rtk-query'
import { RootState, useReduxSelector } from 'redux/store'
import {
  usrSettingsSelector,
  configApiProgramSettingsSelector,
  mainNavigationLayoutSelector,
} from 'redux/selectors'
import { SchedulingSidebar } from '@valerahealth/scheduling'
import { PatientSearchGrid } from '../PatientSearch'
import ProviderCaseload from '../Caseload'
import TreatmentRoom from '../TreatmentRoom'
import NavHeader from '../NavHeader/NavHeader'
import PageNotFound from '../PageNotFound'
import ViewItemPopup from '../ViewItemPopup'
import UserMessages from '../common/UserMessage/userMessages'
import {
  loadQuestionnairesTemplates,
  loadMediaTemplates,
  loadConsentFormsTemplates,
} from '../../redux/actions/toolsTemplatesActions'
import { receiveNewChannelMessage } from '../../redux/actions/treatmentRoom'
import { receiveNewNotification } from '../../redux/actions/caseloadActions'
import {
  hideConfirmation,
  cancelEditOOO,
} from '../../redux/actions/globalActions'
import ErrorPage from '../ErrorPage'
import EditPatientPopup from '../common/EditPatientPopup'
import UserProfile from '../UserProfile'
import { planType, routesEnum } from '../utilities/enums'
import './style.less'
import EditDatesPopup from '../UserProfile/EditDatesPopup'
import TreatmentTimeline from '../TreatmentTimeline'
import TreatmentBasicInfo from '../TreatmentBasicInfo'
import RestorePatient from '../common/RestorePatient'
import Calendar, { selectCanViewCalendar } from '../Calendar'

const PlanTemplate = lazy(() => import('../PlanTemplate'))
const TreatmentBilling = lazy(() => import('../TreatmentBilling'))
const TreatmentAlerts = lazy(() => import('../TreatmentAlerts'))
const CareTeamNew = lazy(() => import('../CareTeamNew'))
const CareTeam = lazy(() => import('../CareTeam'))
const TreatmentDocuments = lazy(() => import('../TreatmentDocuments'))
const TreatmentData = lazy(() => import('../TreatmentData'))
const TreatmentAppointments = lazy(() => import('../TreatmentAppointments'))
const LegacyTreatmentAppointments = lazy(
  () => import('../LegacyTreatmentAppointments'),
)
const TreatmentNotes = lazy(() => import('../TreatmentNotes'))
const EditNote = lazy(() => import('../TreatmentNotes/EditNote'))
const Supervision = lazy(() => import('../Supervision'))
const StaffCaseload = lazy(() => import('../StaffCaseload'))
const Reports = lazy(() => import('../Reports'))

const PROJECT_DISPLAY_NEW_CARE_TEAM_TAB =
  process.env.PROJECT_DISPLAY_NEW_CARE_TEAM_TAB === 'true'

function mapStateToProps(state: RootState) {
  const canViewCalendar = selectCanViewCalendar(state)

  const [search, tasks, calendar] = checkPermissions(state, [
    Permission.LandingPage_CmPatientsearch,
    Permission.LandingPage_CmTasks,
    Permission.LandingPage_CmCalendar,
  ])

  let landingPage: string = routesEnum.CASELOAD
  switch (true) {
    case calendar && canViewCalendar && process.env.PROJECT_PROVIDER_CALENDAR:
      landingPage = routesEnum.CALENDAR
      break
    case search:
      landingPage = routesEnum.PATIENT
      break
    case tasks:
      landingPage = routesEnum.TASKS
      break
    default:
      break
  }

  return {
    landingPage,
    user: state.global.user,
    providerList: state.program.configurations.providerList,
    confirmation: state.global.confirmation,
    displayError: state.global.displayError,
    userMessageArray: !!state.global.userMessageArray?.length,
    callInProgress: state.global.apiCallsInProgress > 0,
    toolsTemplates: state.toolsTemplates,
    editedPatient: state.treatmentRoom.editedPatient,
    configurations: state.program.configurations,
    editedOOO: state.provider.editedOOO,
    restorePopup: state.treatmentRoom.restorePopup,
  }
}

const mapDispatchToProps = {
  loadQuestionnairesTemplates,
  loadMediaTemplates,
  loadConsentFormsTemplates,
  receiveNewChannelMessage,
  receiveNewNotification,
  hideConfirmation,
  cancelEditOOO,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ReduxProps = ConnectedProps<typeof connector>

function AwaitRouteAccess({
  children,
  isLoading,
  hide,
}: {
  children: JSX.Element
  isLoading: boolean
  hide?: boolean
}) {
  if (isLoading) return <CenteredSpinner />
  if (hide) return <PageNotFound />
  return children
}

function DashboardApp({
  landingPage,
  user,
  confirmation,
  userMessageArray,
  displayError,
  editedPatient,
  callInProgress,
  toolsTemplates,
  editedOOO,
  loadQuestionnairesTemplates,
  loadMediaTemplates,
  loadConsentFormsTemplates,
  restorePopup,
  hideConfirmation,
  cancelEditOOO,
}: ReduxProps) {
  /** QUERIES DISPATCHED FROM MIDDLEWARE ON LOGIN */

  const { programId } = useReduxSelector(
    (state) => ({
      idToken: state.auth.session?.idToken.jwt,
      programId: state.auth.session?.user.programId,
    }),
    shallowEqual,
  )
  const programSettingsSelector = useMemo(
    () => careManagerApi.endpoints.getProgramSettings.select(programId || ''),
    [programId],
  )

  const { data: cmApiProgramSettingsRes, isCmProgramSettingsLoading } =
    useReduxSelector((state) => {
      const { isUninitialized, isLoading, data } =
        programSettingsSelector(state)
      return {
        data,
        isCmProgramSettingsLoading: isLoading || isUninitialized,
      }
    }, shallowEqual)

  const { data: userSettingsRes, isUserSettingsLoading } = useReduxSelector(
    (state) => {
      const { data, isLoading, isUninitialized } = usrSettingsSelector(state)
      return {
        data,
        isUserSettingsLoading: isLoading || isUninitialized,
      }
    },
    shallowEqual,
  )

  const {
    data: configProgramSettingsRes,
    isConfigProgramSettingsLoading,
    layout,
  } = useReduxSelector((state) => {
    const { data, isLoading, isUninitialized } =
      configApiProgramSettingsSelector(state)

    const layout = mainNavigationLayoutSelector(state)
    return {
      data: data?.getProgramSettings,
      isConfigProgramSettingsLoading: isLoading || isUninitialized,
      layout,
    }
  }, shallowEqual)

  const isWellness = configProgramSettingsRes?.flags?.isWellness

  const displayNote =
    cmApiProgramSettingsRes?.clinicalNotes.active &&
    userSettingsRes?.permissions.accessToNotes
  const displayNoteLoading = isUserSettingsLoading || isCmProgramSettingsLoading

  /** END QUERIES DISPATCHED FROM MIDDLEWARE ON LOGIN */

  const { pathname } = useLocation()

  // load tools template here because we need them in track card
  useEffect(() => {
    if (!toolsTemplates.questionnaire.list) {
      loadQuestionnairesTemplates()
      loadMediaTemplates()
      loadConsentFormsTemplates()
    }
  }, [
    toolsTemplates.questionnaire.list,
    loadQuestionnairesTemplates,
    loadMediaTemplates,
    loadConsentFormsTemplates,
  ])

  useEffect(() => {
    if (!user) return

    if (process.env.ENV === 'TST' || process.env.ENV === 'PRD') {
      datadogRum.setUser({
        userId: user.id,
        profileId: user.profile.id,
        email: user.profile.email,
        programId,
      })
    }
    if (process.env.ENV === 'PRD') {
      datadogRum.init({
        applicationId: '2bc34922-95cb-4e3b-a4ef-a3a05942d49e',
        clientToken: 'pubb81adec58d869efeb8c4fd58fb87b9a5',
        site: 'datadoghq.com',
        service: 'cm-dashboard-react---prod',
        env: process.env.ENV,
        version: process.env.APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
      })
    } else if (process.env.ENV === 'TST') {
      datadogRum.init({
        applicationId: '05663a7a-bfab-4741-8f3d-976e19edc1f1',
        clientToken: 'pubcdef3ef90bc968ae2c2012f674df7b22',
        site: 'datadoghq.com',
        service: 'cm-dashboard-react---test',
        env: process.env.ENV,
        version: process.env.APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
      })
    }

    console.log('User version: ', process.env.APP_VERSION)
  }, [user, programId])

  const planTemplates = <LazyComponent Component={PlanTemplate} />

  return (
    <div className="dashboard-app">
      {restorePopup?.display && <RestorePatient {...restorePopup.details} />}
      {editedOOO && (
        <EditDatesPopup
          {...editedOOO}
          handleClose={() => {
            cancelEditOOO()
          }}
        />
      )}
      {confirmation.display && (
        <Confirmation
          details={confirmation.details}
          callback={confirmation.callback}
          onClosed={hideConfirmation}
          btns={confirmation.btns}
        />
      )}
      {userMessageArray && <UserMessages />}
      {displayError && <Navigate to="/error" replace />}
      {callInProgress && <CallLoader />}
      <ViewItemPopup />
      {editedPatient && <EditPatientPopup />}
      {!~pathname.indexOf('/telehealth') && <NavHeader />}
      <div className="app-content">
        <AlertNotifications />
        <Routes>
          <Route
            element={
              <>
                <Outlet />
                <SchedulingSidebar />
              </>
            }
          >
            <Route
              path="templates/"
              element={
                <AwaitRouteAccess
                  isLoading={isConfigProgramSettingsLoading}
                  hide={layout.tracksHidden}
                >
                  {planTemplates}
                </AwaitRouteAccess>
              }
            >
              <Route path=":subMenuTemplate/" element={planTemplates}>
                <Route path=":selectedId" element={planTemplates} />
              </Route>
              <Route
                path="*"
                element={<Navigate to={planType.POPULATION} replace />}
              />
            </Route>
            <Route
              path="caseload"
              element={
                <AwaitRouteAccess
                  isLoading={isConfigProgramSettingsLoading}
                  hide={layout.caseloadHidden}
                >
                  <ProviderCaseload />
                </AwaitRouteAccess>
              }
            />
            <Route
              path="patients/*"
              element={
                <AwaitRouteAccess
                  isLoading={isConfigProgramSettingsLoading}
                  hide={layout.patientsHidden}
                >
                  <PatientSearchGrid />
                </AwaitRouteAccess>
              }
            />
            <Route
              path="tasks/*"
              element={
                <AwaitRouteAccess
                  isLoading={isConfigProgramSettingsLoading}
                  hide={layout.tasksHidden}
                >
                  <TaskSearchGrid />
                </AwaitRouteAccess>
              }
            />
            <Route
              path="/caseload/treatment/:treatmentId"
              element={
                <AwaitRouteAccess
                  isLoading={
                    isConfigProgramSettingsLoading || isUserSettingsLoading
                  }
                >
                  <TreatmentRoom />
                </AwaitRouteAccess>
              }
            >
              <Route index element={<Navigate to="timeline" />} />
              <Route path="timeline" element={<TreatmentTimeline />} />
              <Route path="basic-info" element={<TreatmentBasicInfo />} />
              <Route
                path="billing"
                element={
                  <AwaitRouteAccess
                    isLoading={isConfigProgramSettingsLoading}
                    hide={!isWellness}
                  >
                    <LazyComponent Component={TreatmentBilling} />
                  </AwaitRouteAccess>
                }
              />
              <Route
                path="alerts"
                element={<LazyComponent Component={TreatmentAlerts} />}
              />
              <Route
                path="careTeamNew"
                element={
                  <AwaitRouteAccess
                    isLoading={false}
                    hide={!PROJECT_DISPLAY_NEW_CARE_TEAM_TAB}
                  >
                    <LazyComponent Component={CareTeamNew} />
                  </AwaitRouteAccess>
                }
              />
              <Route
                path="careteam"
                element={<LazyComponent Component={CareTeam} />}
              />
              <Route
                path="documents"
                element={<LazyComponent Component={TreatmentDocuments} />}
              />
              <Route
                path="data"
                element={<LazyComponent Component={TreatmentData} />}
              />
              <Route
                path="appointments"
                element={
                  <AwaitRouteAccess
                    isLoading={isConfigProgramSettingsLoading}
                    hide={false}
                  >
                    <LazyComponent
                      Component={
                        configProgramSettingsRes?.appointmentSettings?.flags
                          ?.useSchedulingAppt
                          ? TreatmentAppointments
                          : LegacyTreatmentAppointments
                      }
                    />
                  </AwaitRouteAccess>
                }
              />
              <Route path="plan/*" element={planTemplates}>
                <Route path=":selectedId" element={planTemplates} />
              </Route>
              <Route
                path="note"
                element={
                  <AwaitRouteAccess
                    isLoading={displayNoteLoading}
                    hide={!displayNote}
                  >
                    <LazyComponent Component={TreatmentNotes} />
                  </AwaitRouteAccess>
                }
              />
              <Route
                path="note/:noteId/:type?"
                element={
                  <AwaitRouteAccess
                    isLoading={displayNoteLoading}
                    hide={!displayNote}
                  >
                    <LazyComponent Component={EditNote} />
                  </AwaitRouteAccess>
                }
              />
            </Route>
            <Route
              path="supervision/*"
              element={
                <AwaitRouteAccess
                  isLoading={
                    isUserSettingsLoading || isConfigProgramSettingsLoading
                  }
                  hide={
                    layout.supervisionHidden ||
                    !(userSettingsRes?.role === 'Supervisor')
                  }
                >
                  <LazyComponent Component={Supervision} />
                </AwaitRouteAccess>
              }
            >
              <Route
                path="staff"
                element={<LazyComponent Component={StaffCaseload} />}
              />
              <Route
                path="reports"
                element={<LazyComponent Component={Reports} />}
              />
              <Route path="*" element={<Navigate to="staff" />} />
            </Route>
            <Route path="/profile" element={<UserProfile />} />
            <Route
              path="/calendar"
              element={
                <AwaitRouteAccess
                  isLoading={isConfigProgramSettingsLoading}
                  hide={layout.calendarHidden}
                >
                  <Calendar />
                </AwaitRouteAccess>
              }
            />
            <Route path="/loading" element={<CenteredSpinner />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/404page" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to={landingPage} replace />} />
          </Route>
        </Routes>
      </div>
    </div>
  )
}

export default connector(DashboardApp)
