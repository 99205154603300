import { useState } from 'react'
import { useOutletContext } from 'react-router'
import { Button, Grid } from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import { TreatmentStatusEnum } from '@valerahealth/rtk-query'
import {
  ContentCopy as ContentCopyIcon,
  Send as SendIcon,
} from '@valerahealth/ui-components/icons'
import { ControlledTextField } from '@valerahealth/ui-components/form'
import { useReduxSelector } from 'redux/store'
import { AppInvitePopup } from 'components/common/PatientAppInviteForm'
import { TreatmentRoomRouteContext } from '../utilities/treatments'

const PeripheralDevices = ({ link }: { link?: string | null }) => {
  const { treatmentProfile, treatment } =
    useOutletContext<TreatmentRoomRouteContext>()

  const displayFitbitLink = useReduxSelector(
    (state) => state.program.configurations?.fitbitIntegration,
  )

  const { t } = useTranslation()

  const [displaySendAddPopup, setDisplaySendAddPopup] = useState(false)

  return (
    <>
      <AppInvitePopup
        open={displaySendAddPopup}
        onCancel={() => {
          setDisplaySendAddPopup(false)
        }}
        onSuccess={() => {
          setDisplaySendAddPopup(false)
        }}
        treatmentId={treatment.id}
        email={(treatmentProfile.primaryEmail || treatmentProfile.email)!}
        phone={treatmentProfile.primaryPhone || treatmentProfile.phone}
      />
      <Grid item container alignItems="center" spacing={3} xs={12}>
        <Grid item xs={6}>
          {t('wirelessScale')}
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField fullWidth label={t('deviceID')} name="scaleId" />
        </Grid>
      </Grid>
      {displayFitbitLink && link && (
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={6}>
            <Button
              variant="text"
              endIcon={<ContentCopyIcon />}
              onClick={() => navigator.clipboard.writeText(link)}
            >
              {t('fitbitLink')}
            </Button>
          </Grid>
        </Grid>
      )}
      {treatment?.status === TreatmentStatusEnum.ACTIVE &&
        treatmentProfile?.device?.useMobileApp && (
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={6}>
              <Button
                variant="text"
                startIcon={<SendIcon />}
                onClick={() => {
                  setDisplaySendAddPopup(true)
                }}
              >
                {t('sendAppInvite')}
              </Button>
            </Grid>
          </Grid>
        )}
    </>
  )
}

export default PeripheralDevices
