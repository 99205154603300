import { ReactElement, cloneElement, useState } from 'react'
import { useNavigate } from 'react-router'
import { type DialogProps, Modal } from '@valerahealth/ui-components/base'
import LABELS from 'locales/en'
import RegisterTreatmentForm, {
  RegisterTreatmentFormProps,
} from './RegisterTreatmentForm'

export type RegisterTreatmentModalProps = {
  open: boolean
  onCancel: () => void
  modalDialogProps?: Omit<DialogProps, 'onClose' | 'open'>
} & Pick<RegisterTreatmentFormProps, 'onSuccess'>

export function RegisterTreatmentModal({
  open,
  onSuccess,
  onCancel,
  modalDialogProps,
}: RegisterTreatmentModalProps) {
  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      onClose={onCancel}
      dialogProps={{
        keepMounted: false,
        fullWidth: true,
        maxWidth: 'md',
        ...modalDialogProps,
      }}
      title={LABELS.addNewPatient}
    >
      <RegisterTreatmentForm
        onSuccess={
          onSuccess ||
          (({ treatmentId }) => {
            navigate(`/caseload/treatment/${treatmentId}/basic-info`)
          })
        }
        onCancel={onCancel}
      />
    </Modal>
  )
}
export type RegisterTreatmentButtonProps = Omit<
  RegisterTreatmentModalProps,
  'open' | 'onCancel'
> & {
  /** the button that has an onClick handler injected to control the modal open */
  children: ReactElement
}
export function RegisterTreatmentButton({
  children,
  ...rest
}: RegisterTreatmentButtonProps) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      {cloneElement(children, { onClick: () => setModalOpen(true) })}
      <RegisterTreatmentModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        {...rest}
      />
    </>
  )
}
